import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg';

const Footer = (props) => (
    <footer id="footer">

{/* <section>
        <h2>Contact</h2>
            <p>Please feel free to drop us an <a href="mailto:jon.wiltshire@poolgroup.co">email</a> / <a href="tel:07788407516">call</a> with any questions you have or help you may require. We are always ready to help!</p>
        </section> */}
{/* 
        <section>
            <h2>&nbsp;</h2>
            <dl className="alt">
                <dt>Founder</dt>
                <dd>Jon Wiltshire</dd>
                <dt>Phone</dt>
                <dd><a href="tel:07788407516">07788407516</a></dd>
                <dt>Email</dt>
                <dd><a href="mailto:jon.wiltshire@poolgroup.co">jon.wiltshire@poolgroup.co</a></dd>
            </dl>

        </section> */}



        <p className="copyright">&copy; Addictive Pixel 2009</p>
    </footer>
)

export default Footer
